import Map from 'ol/Map'
import { Feature, View } from 'ol'
import { fromLonLat, get as getProjection } from 'ol/proj'
import { getWidth } from 'ol/extent'
import TileLayer from 'ol/layer/Tile'
import WMTS from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { defaults } from 'ol/interaction/defaults'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { StyleFunction } from 'ol/style/Style'
import { StyleUtils } from './style.utils'

export class MapUtils {
    static initMap(): Map {
        const map = new Map({
            target: 'geoMap',
            view: new View({
                zoom: 19,
                center: fromLonLat([5.909258, 45.682507])
            })
        })

        const resolutions = []
        const matrixIds = []
        const proj3857 = getProjection('EPSG:3857')
        const maxResolution = getWidth(proj3857.getExtent()) / 256
        for (let i = 0; i < 20; i++) {
            matrixIds[i] = i.toString()
            resolutions[i] = maxResolution / Math.pow(2, i)
        }

        const ign = new TileLayer({
            source: new WMTS({
                url: 'https://data.geopf.fr/wmts',
                layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
                matrixSet: 'PM',
                format: 'image/jpeg',
                projection: 'EPSG:3857',
                tileGrid: new WMTSTileGrid({
                    origin: [-20037508, 20037508],
                    resolutions: resolutions,
                    matrixIds: matrixIds
                }),
                style: 'normal'
            })
        })

        map.addLayer(ign)
        return map
    }

   static resetMapToDefaultInteractions(map: Map) {
        map.getInteractions().clear()
        defaults().forEach(interaction => map.addInteraction(interaction))
    }

    static buildVectorLayer(color: string, colorSurface: string) {
        const layerStyleFunction = (feature: Feature) => {
            const styles = [new Style({
                fill: new Fill({
                    color: colorSurface
                }),
                stroke: new Stroke({
                   color: color,
                    width: 5
                }),
                image: new CircleStyle({
                    fill: new Fill({ color: colorSurface }),
                    radius: 7
                })
            }), ...StyleUtils.buildAzimuthArrowStyle(feature)]


            return styles
        }

        return new VectorLayer<any>({
            source: new VectorSource(),
            style: (layerStyleFunction as StyleFunction)
        })
    }
}
