import { Component, inject } from '@angular/core'
import { GeocodingService } from '../../data-access/http/geocoding.service'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { AutocompleteSelectComponent } from '../../../shared/ui/autocomplete-select/autocomplete-select.component'
import { CommonModule } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { RouterLinkWithHref } from '@angular/router'
import { AddressGoogleDto } from '../../model/address-google-dto'

@Component({
  standalone: true,
  imports: [CommonModule, NgSelectModule, AutocompleteSelectComponent, RouterLinkWithHref],
  selector: 'app-control-adresse',
  templateUrl: './control-adresse.component.html',
  styleUrls: ['./control-adresse.component.scss'],
})
export class ControlAdresseComponent {
  private readonly geocodingService = inject(GeocodingService)
  private readonly geomapDomainService = inject(GeomapDomainService)
  searchGoogleResults: AddressGoogleDto[]

  onSelectGoogleAddress(placeId: string): void {
    if (placeId) {
      this.geomapDomainService.positionGoogleMap(placeId)
    }
  }

  onSearchGoogleChange(term: string): void {
    if (term.length >= 4) {
      this.geocodingService.autoCompleteGoogleSearch(term).subscribe((searchResults) => (
        this.searchGoogleResults = searchResults
      ))
    }
  }

}
